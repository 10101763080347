<template lang="pug">
  .hello
    h1 {{ title }}
    p.small-caps {{ subtitle }}
</template>

<script>
export default {
  name: "Index",
  props: {
    title: String,
    subtitle: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h1,
p {
  transition: all 0.5s ease;
  color: var(--home-color);
  font-weight: 600;
}

.hello {
  margin: 0px auto 84px;
  width: 640px;
  max-width: 100vw;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
}
</style>
