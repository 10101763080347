<template lang="pug">
  .header
    #nav
      nav
        transition(name="slide-fade", appear)
          b-nav(small, align='center')
            b-nav-item(to='/', exact, exact-active-class='active') Home
            b-nav-item(to='/projects', exact-active-class='active') Projects
            b-nav-item-dropdown#music-dropdown(text="Music", toggle-class="nav-link-custom", right)
              b-dropdown-item(to='/music', exact, exact-active-class='active') Albums
              b-dropdown-item(to='/schedule', exact, exact-active-class='active') Schedule
              b-dropdown-item(to='/tunes', exact, exact-active-class='active') Tunes
            //- b-link(href='Ben-Schreiber-Resume.pdf', target='_blank') Resume
            b-button#brightness-btn(v-on:click="swapColors")
              b-icon-brightness-high-fill(v-bind:class="mode")
        //- div.header-parent
        //-   transition(name="hr-open", appear)
        //-     hr.header
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      mode: "light"
    };
  },
  methods: {
    swapColors() {
      if (this.mode == "dark") {
        document.documentElement.style.setProperty(
          "--bg-color",
          "var(--light-bg-color)"
        );
        document.documentElement.style.setProperty(
          "--card-color",
          "var(--light-card-color)"
        );
        document.documentElement.style.setProperty(
          "--primary-color",
          "var(--light-primary-color)"
        );
        document.documentElement.style.setProperty(
          "--faded-color",
          "var(--light-faded-color)"
        );
        document.documentElement.style.setProperty(
          "--tertiary-color",
          "var(--light-tertiary-color)"
        );
        document.documentElement.style.setProperty(
          "--home-color",
          "var(--light-home-color)"
        );
        document.documentElement.style.setProperty(
          "--custom-filter",
          "var(--light-custom-filter)"
        );
        this.mode = "light";
      } else {
        document.documentElement.style.setProperty(
          "--bg-color",
          "var(--dark-bg-color)"
        );
        document.documentElement.style.setProperty(
          "--card-color",
          "var(--dark-card-color)"
        );
        document.documentElement.style.setProperty(
          "--primary-color",
          "var(--dark-primary-color)"
        );
        document.documentElement.style.setProperty(
          "--faded-color",
          "var(--dark-faded-color)"
        );
        document.documentElement.style.setProperty(
          "--tertiary-color",
          "var(--dark-tertiary-color)"
        );
        document.documentElement.style.setProperty(
          "--home-color",
          "var(--dark-home-color)"
        );
        document.documentElement.style.setProperty(
          "--custom-filter",
          "var(--dark-custom-filter)"
        );
        this.mode = "dark";
      }
      this.setThemeCookie();
    },
    getThemeCookie() {
      var pref = this.$cookie.get("theme-preference");
      if (pref) {
        if (pref != this.mode) {
          this.swapColors();
        }
      }
    },
    setThemeCookie() {
      this.$cookie.set("theme-preference", this.mode, 1825);
    }
  },
  created() {
    this.getThemeCookie();
  }
};
</script>

<style scoped lang="less">
#brightness-btn {
  position: absolute;
  right: 8px;
  top: 11px;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  padding: 6px 9px;
}
</style>
