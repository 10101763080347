<template lang="pug">
  div
    .home
      img.home-logo(alt="app logo" src="../assets/images/logo-inverted.svg")
      Index(title="Ben Schreiber" subtitle="musician / artist / developer")
</template>

<script>
import Index from "@/components/Index.vue";

export default {
  name: "Home",
  components: {
    Index
  }
};
</script>

<style scoped lang="less">
.home {
  background-image: url("../assets/images/bg1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  .home-logo {
    transition: all 0.5s ease;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: var(--custom-filter);
  }
}
</style>
