import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content: "Ben Schreiber, musician / artist / developer."
        },
        {
          property: "keywords",
          content: "ben schreiber"
        }
      ]
    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "About | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content:
            "Hi, I'm Ben. This website is a space for me to share my personal and professional work, including art/design as well as music projects."
        },
        {
          property: "keywords",
          content: "about,ben schreiber,about ben schreiber"
        }
      ]
    }
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () =>
      import(/* webpackChunkName: "schedule" */ "../views/Gigs.vue"),
    meta: {
      title: "Schedule | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content: "Ben Schreiber's gig schedule."
        },
        {
          property: "keywords",
          content:
            "ben schreiber,gigs,schedule,fiddle,concert,contra dance,dance weekend,ecd,ben schreiber gigs,ben schreiber schedule,ben schreiber fiddle"
        }
      ]
    }
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
    meta: {
      title: "Projects | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content: "Ben Schreiber's projects."
        },
        {
          property: "keywords",
          content:
            "ben schreiber,projects,art,design,3d,rendering,lighting,ben schreiber projects,ben schreiber portfolio"
        }
      ]
    }
  },
  {
    path: "/music",
    name: "Music",
    component: () =>
      import(/* webpackChunkName: "music" */ "../views/Music.vue"),
    meta: {
      title: "Albums | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content: "Ben Schreiber's albums."
        },
        {
          property: "keywords",
          content:
            "ben schreiber,albums,music,discography,ben schreiber albums,ben schreiber music,ben schreiber discography,long story short,night drive,adamantine,beaver jam"
        }
      ]
    }
  },
  {
    path: "/tunes",
    name: "Tunes",
    component: () =>
      import(/* webpackChunkName: "tunes" */ "../views/Tunes.vue"),
    meta: {
      title: "Tunes | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content: "Ben Schreiber's tunes."
        },
        {
          property: "keywords",
          content:
            "ben schreiber,tunes,waltz,jig,reel,original tunes,sheet music,abc,ben schreiber tunes"
        }
      ]
    }
  },
  {
    path: "/100tunes",
    name: "TuneChallenge",
    component: () =>
      import(/* webpackChunkName: "TuneChallenge" */ "../views/100tunes.vue"),
    meta: {
      title: "100 Tunes, 100 Days | bschreiber.com",
      metaTags: [
        {
          name: "description",
          content: "100 tunes in 100 days challenge."
        },
        {
          property: "keywords",
          content:
            "ben schreiber,tunes,waltz,jig,reel,100tunechallenge,100tunes,100days,100 tunes,100 days"
        }
      ]
    }
  },
  {
    path: "/fiddle",
    redirect: { name: "Schedule" }
  },
  {
    path: "/gigs",
    redirect: { name: "Schedule" }
  },
  {
    path: "/albums",
    redirect: { name: "Music" }
  },
  {
    path: "/discography",
    redirect: { name: "Music" }
  },
  {
    path: "/portfolio",
    redirect: { name: "Projects" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
